<template>
    <div>
      <v-card v-if="typeOfUser === 'Free'">
        <v-card-text>
          <br>
          <br>
          <br>
          <div class="text-h5 text-center font-weight-bold" align-center>
            This feature is available on paid version
          </div>
          <br>
          <br>
        </v-card-text>
      </v-card>
      <div class="all-height d-flex justify-center" v-else>
        <div class="all-width">
          <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
            <v-card-text>
              <div class="">
                <div class="d-flex">
                  <p class="mb-1">Home</p>
                  <p class="px-2 mb-1">/</p>
                  <p class="mb-1">{{ metadata.description }}</p>
                </div>
                <div class="d-flex">
                  <p class="black--text font-weight-bold text-h5">Lease Schedule</p>
                  <v-btn class="shadow-off ml-2" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
                </div>
              </div>
              <div v-if="loading && (!metadata._id || !botdetails._id)">
                <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
              </div>
              <div class="py-8" v-else-if="!botdetails._id">
                <div class="body-1 mb-4">
                  No Bot details found
                </div>
              </div>
              <div v-else-if="metadata.stage === 3 && Object.keys(rundetails).length === 0"
                class="pa-8 title text-center grey--text">
                Bot not run
              </div>
              <div v-else>
                <div v-if="metadata.stage !== 3">
                  <v-alert v-if="!savedParameters && hasParameters" type="info" text dense border="left">Save the Input
                    parameters to
                    proceed</v-alert>
                  <v-alert v-else-if="!savedFiles && hasFiles" type="info" text dense border="left">Upload all the
                    required files to start
                    the analysis</v-alert>
                </div>
                <div class="d-flex"
                  v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete')">
                  <v-spacer></v-spacer>
                  <v-btn color="black" v-if="Object.keys(rundetails).length === 0" class="ml-2 shadow-off white--text"
                    height="4vh" small @click="startRun()">
                    <v-icon class="mr-1">mdi-play</v-icon>
                    Start Run
                  </v-btn>
                  <v-btn color="black" v-else class="ml-2 shadow-off white--text" height="4vh" small @click="startRun()">
                    <v-icon class="mr-1">mdi-play</v-icon>
                    Re Run
                  </v-btn>
                </div>
                <v-tabs v-model="tab" color="caccounting" height="30">
                  <v-tab :key="1" v-if="hasParameters" class="text-transform-none justify-start"><v-icon
                      class="mr-2">mdi-play-box-multiple-outline</v-icon>Lease Configuration</v-tab>
                  <v-tab :key="3" class="text-transform-none justify-start"><v-icon
                      class="mr-2">mdi-cloud-upload-outline</v-icon>Lease Master</v-tab>
                  <v-tab :key="4" class="text-transform-none justify-start"
                    @click="generateAnnexurelist(selectedresulttab, 'Summary')"><v-icon
                      class="mr-2">mdi-apps-box</v-icon>Summary</v-tab>
                  <v-tab :key="5" class="text-transform-none justify-start"
                    @click="generateAnnexurelist(selectedresulttab, 'Detailed')"><v-icon
                      class="mr-2">mdi-format-list-bulleted</v-icon>Schedules</v-tab>
                  <v-tab :key="6" class="text-transform-none justify-start"
                    @click="generateAnnexurelist(selectedresulttab, 'Journal Entry')"><v-icon
                      class="mr-2">mdi-note-edit-outline</v-icon> Journal Entries </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                  <v-tab-item :key="1" v-if="hasParameters">
  
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="metadata.stage !== 3 && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add') || $nova.hasRight(right, 'delete'))"
                        small class="mb-2 white--text mt-2" color="black" height="4vh" @click="saveInputParameters()">Save
                        Input Parameters</v-btn>
                    </div>
                    <template v-for="(item, i) in setupList.parameter">
                      <div :key="i" v-if="!item.hidden">
                        <div class="d-flex pa-2 hoverable align-start">
                          <span class="mr-4">{{ i + 1 }}.</span>
                          <div class="">
                            <span class="font-weight-bold">{{ item.code }}</span>
                            <v-icon>mdi-circle-small</v-icon>
                            <span>{{ item.name }}</span>
                          </div>
                          <v-spacer></v-spacer>
                          <div style="max-width: 200px" class="mr-4">
                            <v-chip small class="mr-1" label v-for="(v, k) in item.verification"
                              :key="k">{{ v.code }}</v-chip>
                          </div>
                          <div>
                            <div style="width: 250px">
                              <div v-if="item.type === 'number'" class="parameter-input">
                                <lb-number v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'string'" class="parameter-input">
                                <lb-string v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'date'" class="parameter-input">
                                <lb-date v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'year'" class="parameter-input">
                                <lb-year v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'month'" class="parameter-input">
                                <lb-month v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'time'" class="parameter-input">
                                <lb-time v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'daterange'" class="parameter-input">
                                <lb-daterange v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="item.type === 'timerange'" class="parameter-input">
                                <lb-timerange v-model="inputparametersvalues[item._id]" :hidedetails="true"
                                  :disabled="!(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                              <div v-else-if="
                                [
                                  'numberarray',
                                  'stringarray',
                                  'datearray',
                                  'yeararray',
                                  'montharray',
                                  'timearray',
                                  'daterangearray',
                                  'timerangearray',
                                ].indexOf(item.type) > -1
                              " class="parameter-input">
                                <lb-list :allowrepeat="true" :type="item.type" v-model="inputparametersvalues[item._id]"
                                  :hidedetails="true"
                                  :disabled="(metadata.stage !== 3) || (!$nova.hasRight(right, 'edit') && !$nova.hasRight(right, 'add'))" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>
                      </div>
                    </template>
                    <div class="mb-2 mt-2">
                      <div class="ml-6">
                        Select the GAAP <v-icon class="ml-1">mdi-help-circle-outline</v-icon>
                      </div>
                      <div class="ml-5 mt-2">
                        <v-radio-group v-model="gaapValues" row class="pt-0 mt-0" dense hide-details>
                          <v-radio class="my-0" v-for="n in gaapList" :key="n" :label="n" :value="n"></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="3">
                    <div class="mb-2 mt-2">
                      <div class="mb-2">
                        <div class="d-flex">
                          <div v-if="isError" class="ml-2">
                            <div class="error--text">{{ filecolumproerr || "Unknown Error" }}</div>
                          </div>
                          <v-spacer></v-spacer>
                          <FileUploads :files="setupList.file" :filevalues="uploaddetails"
                            :editable="metadata.stage !== 3" :storepath="'/v2/bot/analysis/storefile/' + botdetails._id"
                            :fileproerr="fileprocesserrors" :fileprostage="fileprocessstage"
                            :fileuppercent="fileuploadpercent" :filesdataPros="filesdata" :fileconfig="fileConfigInfo"
                            @startloading="loading = true" @stoploading="loading = false"
                            @storedData="uploadLeaseMasterFile" :right="right" />
  
                        </div>
                      </div>
                      <div class="d-flex mb-3" v-if="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                        <v-btn small color="black" class="white--text mr-1 mb-1" height="4vh" @click="newLease()"><v-icon
                            class="mr-1">mdi-plus</v-icon>New</v-btn>
                        <div>
                          <lb-string class="seachwith" label="Search..." outlined hidedetails
                            v-model="searchQuery"></lb-string>
                        </div>
                        <v-spacer></v-spacer>
                      </div>
  
  
  
  
                      <lb-datatableaction v-if="paginatedItems.length>0" :headers="headers" :tabledata="paginatedItems" :itemsperpage="itemsPerPage"
                        searchterm="" :bulkActions="false">
  
                        <template v-slot:tbody>
                          <tr v-for="item in paginatedItems" :key="item._id" class="cursor-pointer  "
                            :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in headers">
                              <td v-if="header.visible && header.value !== 'action'" :key="header.value"
                                class="px-2 single-line"
                                :class="{ 'description-header': header.value === 'description', 'single-line': header.value !== 'description' }">
                                {{ item[header.value] }}
                              </td>
                            </template>
                            <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                              <div class="d-flex">
                                <v-tooltip bottom content-class="tooltip-bottom"
                                  v-if="item.lease_status && ($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add'))">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small v-bind="attrs" v-on="on" @click="editItem(item)">
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Lease</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.lease_status">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small v-bind="attrs" v-on="on"
                                      @click="leaseFileFun(item._id, item.lease_document)">
                                      <v-icon>mdi-file</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Lease Document</span>
                                </v-tooltip>
                                <v-tooltip bottom content-class="tooltip-bottom" v-if="item.lease_status">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small v-bind="attrs" v-on="on" @click="rundialog = true">
                                      <v-icon>mdi-cog-play</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Run Lease</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </lb-datatableaction>
                      <div class="d-flex align-center justify-center" v-else>
                        NO DATA
                      </div>

                      <Pagination :list="filteredItems" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />
  
  
  
  
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="4">
                    <div>
                      <div class="d-flex my-2">
                        <div style="min-width: 170px; max-width: 170px">
                          <div v-if="Object.keys(rundetails).length > 1">
                            <template v-for="(v, k) in verificationlist">
                              <div :key="k" :class="`${selectedresulttab === k
                                ? 'border-left-primary'
                                : 'border-left-transparent'
                                }`">
                                <div @click="selectedresulttab = k; generateAnnexurelist(v);"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresulttab === k ? 'grey lighten-4' : ''}`">
                                  <div class="mr-2">
                                    <v-tooltip bottom content-class="tooltip-bottom"
                                      v-if="(rundetails[v._id] || {}).stage === 1">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                      </template>
                                      <span>Completed</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                      (rundetails[v._id] || {}).stage === 4
                                    ">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
                                      </template>
                                      <span>Currently running</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                      </template>
                                      <span>Pending</span>
                                    </v-tooltip>
                                  </div>
                                  <div>
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                          <div v-else>
                            <v-btn color="black" class="white--text mb-4 shadow-off" small @click="downloadResult()">
                              <v-icon class="mr-1">mdi-file-excel</v-icon>
                              Download Excel
                            </v-btn>
                            <div class="" style="max-height:75vh !important;  overflow:scroll">
                            <template v-for="(v, k) in annexurelist">
                              <div :key="k"
                                :class="`${selectedannexure === v.value ? 'border-left-primary' : 'border-left-transparent'}`"
                                v-if="v.name.includes('Summary') "  >
                                <div
                                  @click="selectedannexure = v.value; readAnnexure(v.value, (verificationlist[selectedresulttab] || {})._id)"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedannexure === v.value ? 'grey lighten-4' : ''}`">
                                  <div v-if="v.name.includes('Summary')">
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 px-4" style="min-width: 0">
                          <div class="d-flex">
                            <lb-dropdown v-if="Object.keys(rundetails).length > 1" label="Annexures"
                              v-model="selectedannexure" :items="annexurelist" @change="
                                readAnnexure(
                                  selectedannexure,
                                  (verificationlist[selectedresulttab] || {})._id
                                )
                                " />
                            <v-spacer class="flex-grow-1"></v-spacer>
                            <!-- <v-btn icon><v-icon>mdi-download</v-icon></v-btn> -->
                          </div>
                          <div class="" v-if="currentannexuredata.length > 0">
                            <v-simple-table dense class="simple-table border-on" :fixed-header="true">
                              <template v-slot:default>
                                <thead>
                                  <tr class="">
                                    <template v-for="(v, k) in currentannexuredata[0]">
                                      <th class="grayBackground" :key="k" v-if="k !== '_id'">
                                        {{ (k || "").replaceAll("_", " ") }}
                                      </th>
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in currentannexuredata" :key="k">
                                    <template v-for="(vv, kk) in currentannexuredata[0]">
                                      <td :key="kk" v-if="kk !== '_id'">
                                        {{
                                          typeof v[kk] === "number"
                                            ? $nova.formatNumber(v[kk].toFixed(0))
                                            : v[kk]
                                        }}
                                      </td>
                                    </template>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div v-else class="text-center grey--text title pa-6">
                            No data available
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </v-tab-item>
                  <v-tab-item :key="5">
                    <div>
                      <div class="d-flex my-2">
                        <div style="min-width: 170px; max-width: 170px">
                          <div v-if="Object.keys(rundetails).length > 1">
                            <template v-for="(v, k) in verificationlist">
                              <div :key="k" :class="`${selectedresulttab === k
                                ? 'border-left-primary'
                                : 'border-left-transparent'
                                }`">
                                <div @click="selectedresulttab = k; generateAnnexurelist(v);"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresulttab === k ? 'grey lighten-4' : ''}`">
                                  <div class="mr-2">
                                    <v-tooltip bottom content-class="tooltip-bottom"
                                      v-if="(rundetails[v._id] || {}).stage === 1">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                      </template>
                                      <span>Completed</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                      (rundetails[v._id] || {}).stage === 4
                                    ">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
                                      </template>
                                      <span>Currently running</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                      </template>
                                      <span>Pending</span>
                                    </v-tooltip>
                                  </div>
                                  <div>
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                          <div v-else>
                            <v-btn color="black" height="3.5vh" class="white--text mb-4 shadow-off" small @click="downloadResult()">
                              <v-icon class="mr-1">mdi-file-excel</v-icon>
                              Download Excel Detailed
                            </v-btn>
                            <div class="" style="max-height:75vh !important;  overflow:scroll">

                            <template v-for="(v, k) in annexurelist">
                              <div :key="k" 
                                :class="`${selectedannexure === v.value ? 'border-left-primary' : 'border-left-transparent'}`"
                                v-if="v.name.includes('Detailed')">
                                <div
                                  @click="selectedannexure = v.value; readAnnexure(v.value, (verificationlist[selectedresulttab] || {})._id)"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedannexure === v.value ? 'grey lighten-4' : ''}`">
                                  <div v-if="v.name.includes('Detailed')">
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 px-4" style="min-width: 0">
                          <div class="d-flex">
                            <lb-dropdown v-if="Object.keys(rundetails).length > 1" label="Annexures"
                              v-model="selectedannexure" :items="annexurelist" @change="
                                readAnnexure(
                                  selectedannexure,
                                  (verificationlist[selectedresulttab] || {})._id
                                )
                                " />
                            <v-spacer class="flex-grow-1"></v-spacer>
                            <!-- <v-btn icon><v-icon>mdi-download</v-icon></v-btn> -->
                          </div>
                          <div class="" v-if="currentannexuredata.length > 0">
                            <v-simple-table dense class="simple-table mt-1" :fixed-header="true" :height="tableHeight">
                              <template v-slot:default>
                                <thead>
                                  <tr class="">
                                    <template v-for="(v, k) in currentannexuredata[0]">
                                      <th class="grayBackground" :key="k" v-if="k !== '_id'">
                                        {{ (k || "").replaceAll("_", " ") }}
                                      </th>
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in currentannexuredata" :key="k">
                                    <template v-for="(vv, kk) in currentannexuredata[0]">
                                      <td :key="kk" v-if="kk !== '_id'">
                                        <div v-if="kk === 'Discounting Factor'">
                                          {{ v[kk].toFixed(4) }}
                                        </div>
                                        <div v-else>
                                          {{
                                            typeof v[kk] === "number"
                                              ? $nova.formatNumber(v[kk].toFixed(0))
                                              : v[kk]
                                          }}
                                        </div>
                                      </td>
                                    </template>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div v-else class="text-center grey--text title pa-6">
                            No data available
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="6">
                    <div>
                      <div class="d-flex my-2">
                        <div style="min-width: 170px; max-width: 170px">
                          <div v-if="Object.keys(rundetails).length > 1">
                            <template v-for="(v, k) in verificationlist">
                              <div :key="k" :class="`${selectedresulttab === k
                                ? 'border-left-primary'
                                : 'border-left-transparent'
                                }`">
                                <div @click="selectedresulttab = k; generateAnnexurelist(v);"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedresulttab === k ? 'grey lighten-4' : ''}`">
                                  <div class="mr-2">
                                    <v-tooltip bottom content-class="tooltip-bottom"
                                      v-if="(rundetails[v._id] || {}).stage === 1">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                      </template>
                                      <span>Completed</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else-if="
                                      (rundetails[v._id] || {}).stage === 4
                                    ">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="error">mdi-radiobox-marked</v-icon>
                                      </template>
                                      <span>Currently running</span>
                                    </v-tooltip>
                                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                      </template>
                                      <span>Pending</span>
                                    </v-tooltip>
                                  </div>
                                  <div>
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                          <div v-else>
                            <v-btn color="primary" class="mb-4 shadow-off" small @click="downloadResult()">
                              <v-icon class="mr-1">mdi-file-excel</v-icon>
                              Download Excel
                            </v-btn>
                            <div class="" style="max-height:75vh !important;  overflow:scroll">

                            <template v-for="(v, k) in annexurelist">
                              <div :key="k"
                                :class="`${selectedannexure === v.value ? 'border-left-primary' : 'border-left-transparent'}`"
                                v-if="v.name.includes('Journal Entry')">
                                <div
                                  @click="selectedannexure = v.value; readAnnexure(v.value, (verificationlist[selectedresulttab] || {})._id)"
                                  :class="`px-2 py-1 d-flex align-start cursor-pointer hoverable ${selectedannexure === v.value ? 'grey lighten-4' : ''}`">
                                  <div v-if="v.name.includes('Journal Entry')">
                                    {{ v.name }}
                                  </div>
                                </div>
                              </div>
                            </template>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 px-4" style="min-width: 0">
                          <div class="d-flex">
                            <lb-dropdown v-if="Object.keys(rundetails).length > 1" label="Annexures"
                              v-model="selectedannexure" :items="annexurelist" @change="
                                readAnnexure(
                                  selectedannexure,
                                  (verificationlist[selectedresulttab] || {})._id
                                )
                                " />
                            <v-spacer class="flex-grow-1"></v-spacer>
                            <!-- <v-btn icon><v-icon>mdi-download</v-icon></v-btn> -->
                          </div>
                          <div class="" v-if="currentannexuredata.length > 0">
                            <v-simple-table dense class="" :fixed-header="true" :height="tableHeight">
                              <template v-slot:default>
                                <thead>
                                  <tr class="grey lighten-3">
                                    <template v-for="(v, k) in currentannexuredata[0]">
                                      <th class="grey lighten-3" :key="k" v-if="k !== '_id'">
                                        {{ (k || "").replaceAll("_", " ") }}
                                      </th>
                                    </template>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in currentannexuredata" :key="k">
                                    <template v-for="(vv, kk) in currentannexuredata[0]">
                                      <td :key="kk" v-if="kk !== '_id'">
                                        {{
                                          typeof v[kk] === "number"
                                            ? $nova.formatNumber(v[kk].toFixed(0))
                                            : v[kk]
                                        }}
                                      </td>
                                    </template>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div v-else class="text-center grey--text title pa-6">
                            No data available
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item :key="7">
                    <div class="mb-2 mt-2">
                      <div class="mt-6">
                        <div class="ml-6 mb-2">
                          Select Lease Type
                        </div>
                        <lb-dropdown class="ml-5 mr-5" label="Lease Schedule" :items="leaseTypes" />
                      </div>
                    </div>
  
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <lb-drawer v-model="dialogdoc" width="400" heading="Lease Document" :loading="loadingdocm">
        <template v-slot:body>
          <div>
            <div class="text-center mb-1">Upload lease document.</div>
            <div class="d-flex align-center justify-center">
              <span v-if="docdetailsObj.documentid" @click="downloadTemplate(docdetailsObj.documentid)"
                class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
                {{ docdetailsObj.documentname }}
              </span>
              <lb-file label="" class="py-0 my-0 ml-1" v-model="leaseDocument" :drag="false" hidedetails
                :displayname="false" :loading="fileprocessing[leaseID] === 1" accept=".ppt, .pptx, .doc, .docx"
                @change="uploadFileDocument(leaseID)" />
            </div>
          </div>
        </template>
      </lb-drawer>
  
      <lb-drawer v-model="rundialog" width="350" heading="Confirmation Run Lease" :loading="loading">
        <template v-slot:body>
          <div>
            <div class="text-center text-h5">Confirm</div>
            <div class="text-center mb-1 mb-4">are you sure run the lease</div>
            <div class="d-flex align-center justify-center">
              <v-btn small color="primary" class="ma-2" @click="runLeaseFun()">
                <span>Confirm</span>
              </v-btn>
              <v-btn small color="primary" @click="rundialog = false;">
                <span>Cancel</span>
              </v-btn>
            </div>
          </div>
        </template>
      </lb-drawer>
  
      <lb-drawer v-model="closedialog" width="450" heading="Confirmation Close Lease" :loading="loadingClose">
        <template v-slot:body>
          <div>
            <div class="text-center text-h5">Confirm</div>
            <div class="text-center mb-1 mb-4">are you sure close the lease</div>
            <div class="justify-center closedate">
              <v-row>
                <v-col>
                  <lb-date v-model="leaseendstart" label="lease start date" :disabled="true" :value="leaseendstart" />
                  <lb-date v-model="leaseenddate" label="lease end date" :disabled="true" :value="leaseenddate" />
                </v-col>
              </v-row>
            </div>
            <div class="justify-center closedate">
              <lb-date v-model="closeleaseDate" label="lease close date" :error="errorcloseleaseDate" />
            </div>
            <div class="d-flex align-center justify-center">
              <v-btn small color="primary" class="ma-2" @click="closeLeaseFun()">
                <span>Confirm</span>
              </v-btn>
              <v-btn small color="primary" @click="closedialog = false; closeleaseDate = ''; errorcloseleaseDate = []">
                <span>Cancel</span>
              </v-btn>
            </div>
          </div>
        </template>
      </lb-drawer>
      <lb-drawer v-model="opendialog" width="450" heading="Confirmation Open Lease" :loading="loadingOpen">
        <template v-slot:body>
          <div>
            <div class="text-center text-h5">Confirm</div>
            <div class="text-center mb-1 mb-4">are you sure open the lease</div>
            <div class="d-flex align-center justify-center">
              <v-btn small color="primary" class="ma-2" @click="openLeaseFun()">
                <span>Confirm</span>
              </v-btn>
              <v-btn small color="primary" @click="opendialog = false;">
                <span>Cancel</span>
              </v-btn>
            </div>
          </div>
        </template>
      </lb-drawer>
  
      <lb-drawer v-model="leaseDialog" :heading="leaseData._id ? 'Update Lease Item' : 'Add Lease Item'" width="800"
        :loading="leaseDialogloading">
        <template v-slot:body>
          <div>
            <v-row>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">lessor*</p>
                <lb-string v-model="leaseData.lessor" placeholder="Enter lessor" label="" :error="leaseError.lessor" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Commencement Date*</p>
                <lb-date v-model="leaseData.commencement_date" :error="leaseError.commencement_date"
                  :max="leaseData.end_date" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">End Date*</p>
  
                <lb-date v-model="leaseData.end_date" :error="leaseError.end_date" :min="leaseData.commencement_date" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Installment*</p>
  
                <lb-number v-model="leaseData.installment" label="" placeholder="Enter Installment"
                  :error="leaseError.installment" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Interest Rate*</p>
  
                <lb-number v-model="leaseData.interest_rate" label="" placeholder="Enter Intrest Rate"
                  :error="leaseError.interest_rate" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Installment Frequency*</p>
  
                <lb-dropdown v-model="leaseData.installment_frequency" label="" :items="InstalmentFrequencyItem"
                  :error="leaseError.installment_frequency" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Installment Time*</p>
  
                <lb-dropdown v-model="leaseData.installment_time" label="" :items="IncrementTimeItem"
                  :error="leaseError.installment_time" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Increment Frequency*</p>
  
                <lb-number v-model="leaseData.increment_frequency" label="" :error="leaseError.increment_frequency"
                  min="1" :value="1" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Increment Value*</p>
  
                <lb-number v-model="leaseData.increment_value" label="" :error="leaseError.increment_value" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Increment Rate*</p>
  
                <lb-number v-model="leaseData.increment_rate" label="" :error="leaseError.increment_rate" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Increment Type</p>
  
                <lb-dropdown label="" :items="IncrementTypeItem" :error="leaseError.increment_type"
                  v-model="leaseData.increment_type" values="Normal" />
              </v-col>
              <v-col cols="6" class="my-0 py-1" v-if="leaseData.increment_type === 'Recurring'">
                <p class="ma-0 mb-1">Increment on Increment*</p>
  
                <lb-number v-model="leaseData.increment_on_increment" label="" :error="leaseError.increment_on_increment"
                  min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Security Deposit*</p>
  
                <lb-number v-model="leaseData.security_deposit" label="" :error="leaseError.security_deposit" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Residual Value*</p>
  
                <lb-number v-model="leaseData.residual_value" label="" :error="leaseError.residual_value" min="0" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="ma-0 mb-1">Initial Expense*</p>
  
                <lb-number v-model="leaseData.initial_expense" label="" :error="leaseError.initial_expense" min="0" />
              </v-col>
  
  
  
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="black" class="white--text " height="3vh" @click="leaseUpdate()" v-if="leaseData._id">
            <span>Update</span>
          </v-btn>
          <v-btn small color="black" class="white--text " height="3vh" @click="leaseCreate()" v-else>
            <span>Save</span>
          </v-btn>
        </template>
      </lb-drawer>
  
      <lb-drawer v-model="modificationdialog" width="350" heading="Lease Modification" :loading="modificationdloading">
        <template v-slot:body>
          <div>
            <div class="text-center text-h5">Confirm</div>
            <div class="text-center mb-1 mb-4">are you sure modification the lease</div>
            <div class="d-flex align-center justify-center">
              <v-btn small color="primary" class="ma-2" @click="modificationLeaseFun()">
                <span>Confirm</span>
              </v-btn>
              <v-btn small color="primary" @click="modificationdialog = false;">
                <span>Cancel</span>
              </v-btn>
            </div>
          </div>
        </template>
      </lb-drawer>
  
  
    </div>
  </template>
  
  <script>
  //  import InputParamerter from '../../../../../views/conserve/InputParamerter.vue';
  import FileUploads from '../../../../views/conserve/FileUploads.vue';
  import Pagination from '../../../../views/common/Pagination.vue';
  export default {
    // name: 'financialclose_automation_bots_component',
    name: 'financialclose_automation_lease_bots',
    // props: ['props'],
    data: function () {
      return {
        right: "financialclose_pbc_project",
        project: "",
        loading: false,
        id: "",
        tab: 0,
        metadata: {},
        botproject: {},
        botdetails: {},
        setupList: {},
        verificationlist: [],
        parameters_detail: [],
        hasParameters: false,
        savedParameters: false,
        inputparametersvalues: {},
        uploaddetails: {},
        fileuploaddata: {},
        fileprocesserrors: {},
        fileuploadpercent: {},
        fileprocessstage: {},
        filesdata: {},
        processedFiles: false,
        savedFiles: false,
        hasFiles: false,
        rundetails: {},
        annexurelist: [],
        selectedresulttab: 0,
        selectedannexure: "",
        currentannexuredata: [],
        annexuredata: {},
        fileConfigInfo: {},
        botid: "6470e55d999d5833d9d58224",
        isValid: false,
        typeOfUser: "Paid",
        gaapList: ["ASC 842", "IFRS 16", "IND AS 116"],
        gaapValues: 'IFRS 16',
        dialog: false,
        searchQuery: '',
        headers: [
          {
            value: "lease_id",
            text: "Lease Id",
            sortable: false,
            filterable: false,
            visible: true,
          },
          {
            value: "lessor",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Lessor",
          },
          {
            value: "commencement_date",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Commencement Date",
          },
          {
            value: "end_date",
            sortable: false,
            filterable: false,
            visible: true,
            text: "End Date",
          },
          {
            value: "installment",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Installment",
          },
          {
            value: "interest_rate",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Interest Rate",
          },
          {
            value: "installment_frequency",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Installment Frequency",
          },
          {
            value: "increment_value",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Increment Value",
          },
          {
            value: "increment_rate",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Increment Rate",
          },
          {
            value: "increment_type",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Increment Type",
          },
          {
            value: "increment_on_increment",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Increment on Increment",
          },
          {
            value: "security_deposit",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Security Deposit",
          },
          {
            value: "residual_value",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Residual Value",
          },
          {
            value: "initial_expense",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Initial Expense",
          },
          {
            value: "increment_frequency",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Increment Frequency",
          },
          {
            value: "installment_time",
            sortable: false,
            filterable: false,
            visible: true,
            text: "InstallmentTime",
          },
          {
            value: "action",
            sortable: false,
            filterable: false,
            visible: true,
            text: "Action",
          },
        ],
        leaseTypes: ["Scope change", "Lease Term Change", "Lease Consideration Change"],
        leaseData: {
          increment_type: "Normal",
          installment_time: "End"
        },
        leaseDataList: [],
        leaseDialog: false,
        leaseError: false,
        leaseDialogloading: false,
        rundialog: false,
        summaries: [],
        detailed: [],
        journalEntries: [],
        formatedData: [],
        bulkleasefile: null,
        fileprocessing: {},
        filecolumproerr: {},
        isError: false,
        clientid: "",
        storedData: [],
        leaseTabType: "",
        closedialog: false,
        loadingClose: false,
        opendialog: false,
        loadingOpen: false,
        errorcloseleaseDate: [],
        closeleaseDate: "",
        leaseID: '',
        leasedocID: '',
        leaseenddate: '',
        leaseendstart: '',
        leaseDocument: null,
        loadingdocm: false,
        dialogdoc: false,
        docdetailsObj: {},
        modificationdialog: false,
        modificationdloading: false,
        IncrementTypeItem: ["Normal", "OneTime", "Recurring"],
        IncrementTimeItem: ["Beginning", "End"],
        Increment_Type: '',
        InstalmentFrequencyItem: ["Monthly", "Quarterly", "SemiAnnual", "Annual"],
        itemsPerPage: 8,
        paginatedItems: [],
        tableHeight:''
  
      }
    },
    props: {
      // botid: {
      //   type: String,
      //   default: ""
      // },
    },
    components: {
      FileUploads,
      Pagination
    },
    created() {
      this.refreshData();
      this.tableHeight = window.innerHeight - 300;
    },
    activated() {
      this.refreshData();
    },
    methods: {
      formateData() {
        let ObjectTemp = {};
        let ArrayTemp = [];
        this.leaseDataList.forEach((item, index) => {
          ObjectTemp = {
            __key: index,
            __formatedkey: index,
            _id: item._id,
            lease_id: item.lease_id,
            lessor: item.lessor,
            commencement_date: item.commencement_date,
            end_date: item.end_date,
            installment: item.installment,
            interest_rate: item.interest_rate,
            installment_frequency: item.installment_frequency,
            increment_value: item.increment_value,
            increment_rate: item.increment_rate,
            increment_type: item.increment_type,
            increment_on_increment: item.increment_on_increment,
            security_deposit: item.security_deposit,
            residual_value: item.residual_value,
            initial_expense: item.initial_expense,
            increment_frequency: item.increment_frequency,
            installment_time: item.installment_time,
            lease_status: item.status,
            lease_document: item.lease_document
          };
          ArrayTemp.push(ObjectTemp);
        });
        this.formatedData = ArrayTemp;
      },
      separateArrays() {
        this.annexurelist.forEach(item => {
          if (item.name.includes('Summary')) {
            this.summaries.push(item);
          } else if (item.name.includes('Detailed')) {
            this.detailed.push(item);
          } else if (item.name.includes('Journal Entry')) {
            this.journalEntries.push(item);
          }
        });
      },
      openFileDialog() {
        // Programmatically open file dialog when the icon is clicked
        this.$refs.fileInput.click();
      },
      setPaginatedData(paginatedData) {
        this.paginatedItems = paginatedData;
      },
      init() {
        if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
          this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
        }
      },
      refreshData() {
        this.id = this.$route.params.id || "";
        this.userid = this.$store.state.user.id;
        this.clientid = this.$store.state.client;
        this.currentannexuredata = [];
        this.annexuredata = {};
        this.getData();
        this.init();
        // this.generateAnnexurelist(0,this.leaseTabType);
      },
      getData() {
        this.loading = true;
        this.project = this.$route.params.id;
        this.isError = false;
        return this.axios.post("/v2/financialclose/analysis/getbotdetails/" + this.id).then(dt => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0] || {}
            this.metadata = data.metadata || {};
            this.userlist = [];
            this.userlist = this.metadata.userlist || [];
            this.managerlist = [];
            this.managerlist = this.metadata.managerlist || [];
            this.stage = this.metadata.stage;
          }
        }).then(() => {
          this.axios.post("/v2/bot/lease/get/" + this.id)
            .then((ele) => {
              if (ele.data.status === "success" && ele.data.data.length > 0) {
                this.leaseDataList = ele.data.data || [];
                this.formateData();
              } else throw new Error(ele.data.message);
            })
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
          this.loadBot();
        })
      },
      generateAnnexurelist(index = 0, type) {
        this.leaseTabType = type;
        let ndata = [];
        let selectedverification = this.verificationlist[index] || {};
        let rundata = this.rundetails[selectedverification._id] || {};
        if (Object.keys(rundata).length > 0) {
          let annxdata = rundata;
          let hasannexure = false;
          for (let i = 0; i < annxdata.annexure.length; i++) {
            const el = annxdata.annexure[i];
            if (el.name.includes(type)) { // Check if the name includes the specified type
              ndata.push({ name: el.name, value: el.collection });
              if (el.collection === this.selectedannexure) hasannexure = true;
            }
          }
          if (!hasannexure && ndata.length > 0) {
            this.selectedannexure = ndata[0].value;
          }
          this.readAnnexure(this.selectedannexure, selectedverification._id);
        }
        this.annexurelist = ndata;
      },
  
      readAnnexure(c, v) {
        if (c) {
          this.loading = true;
          Promise.resolve()
            .then(() => {
              return this.axios.post("/v2/bot/analysis/readannexure/" + this.botdetails._id + "/c/" + c + "/v/" + v);
            })
            .then((dt) => {
              if (dt.data.status === "success") {
                this.annexuredata[c] = dt.data.data;
                this.currentannexuredata = this.annexuredata[c];
              } else throw new Error(dt.data.message || "Error reading annexure");
            })
            .catch((err) => {
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              this.currentannexuredata = [];
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
          // }
        }
      },
      loadBot() {
        this.loading = true;
        return this.axios.post("/v2/financialclose/analysis/loadbotdetails/" + this.id + "/b/" + this.botid).then(dt => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0] || {};
            this.botdetails = data.metadata || {};
            this.uploaddetails = data.uploaddetails || {};
            this.setupList = data.setup || {};
            this.verificationlist = this.setupList.verification || {};
            this.parameters_detail = (this.setupList.verification[0] || []).parameters_detail;
            this.inputparametersvalues = this.botdetails.inputparameter || {};
            this.fileConfigInfo = this.setupList?.fileconfig || {};
            this.rundetails = {};
            for (const i of data.rundetails || []) {
              this.rundetails[i.verification] = i;
            }
            this.generateAnnexurelist(0, this.leaseTabType);
  
            // to check if inputparameter are available and if the same is saved
            this.savedParameters = false;
            if ((this.setupList.parameter || []).length > 0) {
              this.hasParameters = true;
              let allparametersaved = true;
              for (let ele of this.setupList.parameter) {
                if (!Object.hasOwnProperty.call((this.botdetails.inputparameter || {}), ele._id)) {
                  allparametersaved = false;
                }
              }
              this.savedParameters = allparametersaved;
            } else {
              this.hasParameters = false;
            }
  
            // set default input parameter values
            let availableids = [];
            for (const k in this.inputparametersvalues) {
              if (Object.hasOwnProperty.call(this.inputparametersvalues, k)) {
                availableids.push(k);
              }
            }
            for (const i of this.setupList.parameter || []) {
              if (availableids.indexOf(i._id) === -1) {
                if (
                  Object.hasOwnProperty.call(
                    this.setupList.inputparameterconfig || {},
                    i._id
                  )
                )
                  this.inputparametersvalues[i._id] = (
                    this.setupList.inputparameterconfig[i._id] || {}
                  ).value;
                else this.inputparametersvalues[i._id] = i?.value;
              }
            }
  
            // to check if files are available and if the same is saved and also flag processing errors
            if ((this.setupList.file || []).length > 0) {
              this.hasFiles = true;
              let allfileprocessed = true;
              let allfilesaved = true;
              let availablefiles = [];
              for (const i of this.botdetails.files || []) {
                availablefiles.push(i.fileid);
              }
              for (let ele of this.setupList.file) {
                let id = ele._id;
                if ((this.uploaddetails[id] || {}).processed !== 1) {
                  allfileprocessed = false;
                }
                if ((this.uploaddetails[id] || {}).processed === -1) this.fileprocesserrors[id] = "Error while processing file";
                if (availablefiles.indexOf(id) === -1 || (this.uploaddetails[id].processed || 0) === -1) allfilesaved = false;
              }
              this.savedFiles = allfilesaved;
              this.processedFiles = allfileprocessed;
            } else {
              this.hasFiles = false;
            }
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        }).finally(() => {
          this.loading = false;
        })
      },
      startRun() {
        this.loading = true;
        this.axios
          .post("/v2/bot/analysis/startrun/" + this.botdetails._id)
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit("sbSuccess", "Run Started");
              this.loadBot();
            } else throw new Error("Error starting run");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      downloadResult() {
        this.loading = true;
        this.axios.post("/v2/bot/analysis/downloadfile/" + this.botdetails._id).then(dt => {
          if (dt.data.status === "success") {
            let data = dt.data.data[0] || {};
            if (data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
            else if (data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
            else if (data.upload) this.$nova.downloadFile(data.upload);
            else return "no file found to download"
          } else {
            throw new Error(dt.data.message || "Error re-opening project");
          }
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      },
      saveInputParameters() {
        const { parameters_detail, inputparametersvalues, $store, botdetails, axios } = this;
        console.log(parameters_detail);
        const FC_IP_4 = parameters_detail.filter(item => item.code === 'FC_IP_4').map(item => item._id);
        const FC_IP_3 = parameters_detail.filter(item => item.code === 'FC_IP_3').map(item => item._id);
  
        const endDateValue = inputparametersvalues[FC_IP_4[0]];
        const startDateValue = inputparametersvalues[FC_IP_3[0]];
  
        if (!startDateValue || startDateValue === '' || !endDateValue || endDateValue === '') {
          $store.commit("sbError", "Please enter both start and end dates");
          return;
        }
  
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);
  
        if (startDate > endDate) {
          $store.commit("sbError", "Start date should be before end date");
          return;
        }
  
        this.loading = true;
        const objectInput = { inputparameter: inputparametersvalues };
        axios.post(`/v2/bot/analysis/storeinputparameter/${botdetails._id}`, {
          data: objectInput,
        })
          .then((ele) => {
            if (ele.data.status === "success") {
              $store.commit("sbSuccess", "Input Parameters Updated");
              this.loadBot();
            } else throw new Error("Error updating input parameters");
          })
          .catch((err) => {
            $store.commit("sbError", err.message || err || "Unknown error!");
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      editItem(item = {}) {
        this.leaseData = { ...item };
        this.leaseError = {};
        this.leaseDialog = true;
        console.log(item);
      },
      modifyItem(item = {}) {
        this.leaseData = { ...item };
        this.leaseDialog = true;
        console.log(item);
      },
      leaseUpdate() {
        this.leaseDialogloading = true;
        this.axios
          .post("/v2/bot/lease/edit/" + this.leaseData._id, {
            data: this.leaseData,
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "update successfully");
              this.leaseDialog = false;
              this.leaseData = {};
              this.refreshData();
            } else {
              this.leaseError = {};
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0) this.leaseError = dt.data.data[0].index0;
              }
              // throw new Error(dt.data.message || "Error Updateing Lease Item");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            // this.leaseDialog = false;
            this.leaseDialogloading = false;
          });
      },
      openLeaseFun() {
        this.loadingOpen = true;
        this.axios
          .post("/v2/bot/lease/openlease/" + this.leaseID)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "Open successfully");
              this.opendialog = false;
              this.refreshData();
            } else {
              throw new Error(dt.data.message || "Error Closeing Lease Item");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loadingOpen = false;
          });
      },
      closeLeaseFun() {
        this.loadingClose = true;
        this.axios
          .post("/v2/bot/lease/closelease/" + this.leaseID, {
            data: [{ close_date: this.closeleaseDate }],
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "Closed successfully");
              this.closedialog = false;
              this.closeleaseDate = "";
              this.errorcloseleaseDate = [];
              this.refreshData();
            } else {
              this.errorcloseleaseDate = [];
              console.log(dt.data.data, "dt.data.data");
              if (dt.data.message.length > 0) {
                if (dt.data.message) this.errorcloseleaseDate = dt.data.message;
              }
              // throw new Error(dt.data.message || "Error Closeing Lease Item");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loadingClose = false;
            // this.closedialog = false;
          });
      },
      runLeaseFun() {
        this.loading = true;
        this.axios
          .post("/v2/bot/analysis/startrun/" + this.botdetails._id)
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit("sbSuccess", "Successfully The Run Lease");
              this.refreshData();
            } else throw new Error("Error starting run lease");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            this.rundialog = false;
          });
        // this.$store.commit("sbSuccess", "Successfully The Run Lease");
        // this.rundialog = false;
      },
      modificationLeaseFun() {
        this.$store.commit("sbSuccess", "Successfully The Modification Lease");
        this.modificationdialog = false;
      },
      newLease() {
        this.leaseData = {
          increment_type: "Normal",
          installment_time: "End",
          installment: 0,
          interest_rate: 0,
          increment_value: 0,
          increment_frequency: 1,
          increment_rate: 0,
          security_deposit: 0,
          residual_value: 0,
          initial_expense: 0,
          increment_on_increment: 0,
        },
          this.leaseError = {};
        this.leaseDialog = true;
      },
      leaseCreate() {
        this.leaseDialogloading = true;
        this.leaseData.project = this.project;
        console.log(this.leaseData, "this.leaseData");
        this.axios.post("/v2/bot/lease/add", {
          data: this.leaseData,
        })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.$store.commit("sbSuccess", "add successfully");
              this.leaseDialog = false;
              this.refreshData();
            } else {
              this.leaseError = {};
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0) this.leaseError = dt.data.data[0].index0;
              }
              // throw new Error(dt.data.message || "Error Adding Lease Item");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.leaseDialogloading = false;
            // this.leaseDialog = false;
          });
      },
      blukleaseFun(key) {
        let file = this.bulkleasefile;
        this.isError = false,
          this.filecolumproerr = {};
        this.fileprocessing[key] = 1;
        if ((file || {}).name) {
          let ext = (file || {}).name.split('.').pop().toLowerCase();
          if (!['xlsx', 'xls', 'xlsm', 'xlsb', 'csv'].includes(ext)) {
            this.isError = true;
            this.filecolumproerr = 'Only xlsx, xls, xlsm, xlsb, csv file extensions are supported';
            return;
          }
          let fl = new FormData();
          fl.append('file', file);
          fl.append('project', this.project);
          this.axios.post('/v2/bot/lease/bulkadd?cid=' + this.clientid, fl, {
            withCredentials: true,
            headers: {
              snclientid: this.clientid,
              'auth-data': JSON.stringify({}),
            }
          })
            .then(response => {
              console.log(response, "response");
              if (response.data.status === "success" && response.data.data.length > 0) {
                this.$store.commit("sbSuccess", "File uploaded");
                this.isError = false;
                this.filecolumproerr = {};
                this.getData()
              } else {
                this.isError = true,
                  this.filecolumproerr = "Error while creating lease master, please try again"
                this.$store.commit("sbError", "Error while creating lease master, please try again");
                throw new Error(response.data.message || "Error Adding Lease Item");
              }
            })
            .catch(error => {
              console.error(error);
              new Error(error || "Error Adding Lease Item");
            }).finally(() => {
              this.bulkleasefile = null;
              this.fileprocessing[key] = 0;
            });
        }
      },
      uploadLeaseMasterFile(filedata) {
        this.loading = true;
        let success = false;
        console.log("filedata=", filedata, "this.project=", this.project);
        this.axios.post("/v2/upload/get/" + filedata.stored[0]?.files[0]?.value)
          .then((dt) => {
            if (dt.data.status === "success") {
              success = true;
              this.loading = true;
              let fileDetails = dt.data.data[0] || {};
              if (fileDetails.proceed != 1) {
                setTimeout(() => {
                  this.loading = true;
                  this.bulkLease(filedata)
                }, 10000);
              } else {
                this.bulkLease(filedata);
              }
            } else throw new Error(dt.data.message || "Error Lease Item");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = (success) ? true : false;
            this.bulkleasefile = null;
          });
      },
      bulkLease(filedata) {
        this.loading = true;
        this.axios.post("/v2/bot/lease/bulkadd/" + this.project, {
          data: [{ "upload": filedata.stored[0].files[0]?.value }]
        })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.loading = false;
              this.$store.commit("sbSuccess", "add successfully");
              this.refreshData();
            } else throw new Error(dt.data.message || "Error Lease Item");
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            this.bulkleasefile = null;
          });
      },
      uploadFileDocument: function (id) {
        if (id) {
          let file = this.leaseDocument;
          console.log(file);
          let ext = ((file || {}).name || "").split(".").pop();
          this.fileprocessing[id] = 1;
          if ((file || {}).name) {
            Promise.resolve().then(() => {
              if (["ppt", "pptx", "doc", "docx"].indexOf(ext) === -1) throw new "Only .ppt, .pptx, .doc, .docx file extension supported";
              return this.$nova.uploadFile(this.axios, file, {
                progressfunction: (e) => {
                  console.log(e);
                }
              })
            }).then(dt => {
              if (dt.data.status === "success" && dt.data.data.length > 0) {
                this.fileprocessing[id] = 2;
                let data = { upload: dt.data.data[0]._id };
                return this.axios.post("/v2/bot/lease/storeleasedocument/" + id, { data: [data] });
              } else throw new "Unable to upload file";
            }).then(dt => {
              if (dt.data.status === "success" && dt.data.data.length > 0) {
                this.$store.commit("sbSuccess", "File uploaded");
                this.refreshData();
              } else throw new "error uploading file";
            }).catch(err => {
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              console.log(err);
            }).finally(() => {
              this.fileprocessing[id] = 0;
              this.leaseDocument = null;
            })
          }
        }
      },
      downloadTemplate(id) {
        this.$nova.downloadFile('', {}, '/v2/bot/lease/download/' + id + '/a');
      },
      leaseFileFun(id, docid) {
        this.leaseID = id;
        this.dialogdoc = true;
        this.docdetailsObj = {};
        if (docid) {
          this.loadingdocm = true;
          this.axios.post("/v2/upload/get/" + docid)
            .then((dt) => {
              if (dt.data.status === "success") {
                let docdetails = dt.data.data[0] || {};
                this.docdetailsObj = { documentname: docdetails.uploadname, documentid: docdetails._id };
              }
            })
            .catch((err) => {
              this.$store.commit("sbError", err.message || err || "Unknown error!");
              console.log(err);
            })
            .finally(() => {
              this.loadingdocm = false;
            });
        }
      },
      modificationItem(item) {
        console.log(item);
        this.modificationdialog = true;
  
      }
    },
    computed: {
      filteredItems() {
        if (this.searchQuery === null) {
          return this.formatedData;
        }
        const searchQuery = this.searchQuery.toLowerCase();
        return this.formatedData.filter(item => {
          for (const key in item) {
            // Skip iteration if the current property is not a string or number
            if (typeof item[key] !== 'string' && typeof item[key] !== 'number') continue;
            // Convert the property value to string for comparison
            const value = String(item[key]).toLowerCase();
            // If the value includes the search query, return true
            if (value.includes(searchQuery)) return true;
          }
          // If none of the properties include the search query, return false
          return false;
        });
      }
    },
    watch: {
      'leaseData.increment_value': function (newValue) {
        if (newValue > 1) {
          this.leaseData.increment_rate = 0;
        }
      },
      'leaseData.increment_rate': function (newValue) {
        if (newValue > 1) {
          this.leaseData.increment_value = 0;
        }
      }
    }
  }
  </script>
  <style scoped>
  .v-file-input {
    --v-file-input-color-border: transparent !important;
  }
  
  .seachwith {
    width: 250px !important;
  }
  
  .closedate {
    width: 250px !important;
    margin-left: 80px !important;
  }

  .all-height{
    height: 95%;
  }
  </style>
  