<template>

    <v-navigation-drawer
        v-model="localValue"
        v-if="!dailogBox && !dailogboxOnly"
        right
        :width="Maxwidth"
        class="position-absolute drawer-shadow overflow-hidden"
        scrollable persistent
    >
    <v-card class="shadow-off overflow-hidden d-flex flex-column" :loading="loading" :disabled="loading" loader-height="2" style="height: 100%;">

        <v-card-title class="align-center py-1 pb-2 px-4">
          <span class="font-weight-semibold text-h6">{{ heading }}</span>
          <v-spacer></v-spacer>
          <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip left content-class="tooltip-left">
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }" class="ml-2">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>Layouts</span>
              </v-tooltip>
            </template>
            <v-list class="pa-2 ma-0 d-flex flex-column align-start radius-medium justify-start all-width">
              <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small" @click="TodailogBox('side')">
                <v-icon class="hoveritem px-2">mdi-page-layout-sidebar-left</v-icon>
                Side Peek
              </v-list-item>
              <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small" @click="TodailogBox('center')">
                <v-icon class="hoveritem px-2">mdi-page-layout-body</v-icon>
                Center Peek
              </v-list-item>
              <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small" @click="TodailogBox('full')">
                <v-icon class="hoveritem px-2">mdi-page-layout-header-footer</v-icon>
                Fullscreen
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="CloseDrawer()" class="ml-2">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
      
        <v-divider></v-divider>
        <v-card-text class="pt-4 px-6" style="overflow-y: auto; flex-grow: 1;">
          <slot name="body" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="action-shadow">
          <slot name="actions" />
        </v-card-actions>
      
      </v-card>
      
    </v-navigation-drawer>
    <v-dialog v-else v-model="value" :width="width || 850"  scrollable >
        <v-card class="shadow-off" :loading="loading" :disabled="loading" loader-height="2">
            <v-card-title class="white align-center py-2 px-4">
              <span class="font-weight-semibold text-h6">{{ heading }}</span>
              <v-spacer></v-spacer>
              <v-menu  :close-on-content-click="false" offset-y transition="slide-y-transition" v-if="!dailogboxOnly">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on"  class="ml-2 "><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                </template>
    
                <v-list class="pa-2 ma-0 d-flex flex-column align-start radius-medium justify-start all-width">
                 <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small" @click="TodailogBox('side')">
                    <v-icon class="hoveritem px-2">mdi-page-layout-sidebar-left</v-icon>
                    Side Peek
                 </v-list-item>
                 <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small"  @click="TodailogBox('center')" >
                    <v-icon class="hoveritem px-2">mdi-page-layout-body</v-icon>
                    Center Peek
                 </v-list-item>
                 <v-list-item class="pl-3 pr-6 all-width pa-0 hoveritem radius-small" @click="TodailogBox('full')">
                    <v-icon class="hoveritem px-2">mdi-page-layout-header-footer</v-icon>
                    Fullscreen
                 </v-list-item>
                
                </v-list>
              </v-menu>
              <v-btn icon @click="CloseDrawer()" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-8">
              <slot name="body" />
            </v-card-text>
            <v-card-actions class="py-4 px-6">
              <slot name="actions" />
            </v-card-actions>
        </v-card>
      </v-dialog>

    
</template>

<script>

export default {
    // name: 'ItemDetails',
    data:  function() {
        return {
            dailogBox :false,
            localValue: this.value, 
            }
            },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        heading: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        Maxwidth:{
            type:String,
            default:'40%'
        },
        dailogboxOnly:{
            type:Boolean,
            default:false
        },
        width:{
            type: String,
            
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscPress);
        this.dailogBox= this.dailogboxOnly? true:false;
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscPress);
    },
    methods: {
        handleEscPress(event) {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.CloseDrawer();
            }
        },
        openDrawer() 
        {
            this.value = true;
            this.localValue = true; // Update the local copy
            this.$emit("input", this.localValue);
        },
        CloseDrawer()
        {   
          this.dailogBox = false;
          this.localValue = false; // Update the local copy
          this.$emit("input", this.localValue);
            // this.dailogBox=false;
            // this.value= false
        },
        TodailogBox(peek){
            // if(peek==='side')
            // {
            //     this.Maxwidth="40%"
            //     this.dailogBox=false
            // }
            // else if (peek==='full')
            // {
            //     this.Maxwidth="86%"
            //     this.dailogBox=false
            // }
            // else{
            //     this.dailogBox = !this.dailogBox
            // }
            if (peek === "side") {
            this.Maxwidth = "40%";
            this.dailogBox = false;
          } else if (peek === "full") {
            this.Maxwidth = "86%";
            this.dailogBox = false;
          } else {
            this.dailogBox = !this.dailogBox;
          }
        }
    },
    watch: {
      value(newVal) {
        this.localValue = newVal;
      },
      localValue(newVal) {
        this.$emit('input', newVal); 
        this.$store.commit('setdrawerOpen', newVal);
      },
    },
  };
  </script>
<style scoped>

.v-navigation-drawer{
    position: fixed !important;
    z-index: 100;
}
.v-dialog{
    height: 50vh;
}
.drawer-shadow{
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.action-shadow{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.v-navigation-drawer__content{
    overflow: hidden !important;
}
.hoveritem:hover{
    background-color: #6B67CE;
    color: white !important;
}
</style>
